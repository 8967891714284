
import axios from 'axios';
export default {
    
        async createStockMoveTypes(params)  {
            return await axios.post(`stock_move_types/create` , params)
        },
        async createStockMoveTypesList(params)  {
            return await axios.post(`stock_move_types/create/list` , params)
        },
        async updateStockMoveTypesColumn(column , value , data)  {
            return await axios.put(`stock_move_types/update_list?${column}=${value}` , data)
        },
        async deleteStockMoveTypesList(list)  {
            return await axios.delete(`stock_move_types/delete_list` , {
                data: {
                    list:list
                }
            })
        },
        async reportStockMoveTypes(column , value)  {
            return await axios.get(`stock_move_types/report?${column}=${value}`)
        },
        async getAllStockMoveTypes()  {
            return await axios.get(`stock_move_types/all`)
        },
        async getOneStockMoveTypes(stock_move_type_id)  {
            return await axios.get(`stock_move_types/all/${stock_move_type_id}`)
        },
        async getStockMoveTypesByColumn(column , value)  {
            return await axios.get(`stock_move_types/filter?column=${column}&value=${value}`)
        },
        async deleteStockMoveTypes(stock_move_type_id)  {
            return await axios.delete(`stock_move_types/delete/${stock_move_type_id}`)
        },
        async updateStockMoveTypes(stock_move_type_id , params)  {
            return await axios.put(`stock_move_types/update/${stock_move_type_id}` , params)
        },
}
        